<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Areas">
                <b-button v-if="$hasPermission($permissions.AddAreas)" variant="primary" @click="$router.push('/area/add')">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add area</span>
                </b-button>
            
                <basic-table :columns="columns" :data="formattedData" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button v-if="$hasPermission($permissions.EditAreas)" variant="warning" @click="$router.push('/area/' + props.row.id + '/edit')" class="mr-1">
                            <feather-icon icon="EditIcon"/>
                        </b-button>
                        <b-button v-if="$hasPermission($permissions.RemoveAreas)" variant="danger" @click="removeArea(props.row.id)" class="mr-1">
                            <feather-icon icon="Trash2Icon"/>
                        </b-button>
                    </div>
                    <div v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <div class="rounded-sm mr-1" :style="{width: '60px', height: '25px', backgroundColor: props.formattedRow[props.column.field] }"></div>
                        {{ props.formattedRow[props.column.field] }}
                    </div>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>

    import BasicTable from '@/views/components/BasicTable'
    import {BButton, BCard, BOverlay} from 'bootstrap-vue'

    export default {
        components: {
            BasicTable,
            BCard,
            BOverlay,
            BButton
        },
        data() {
            return {
                dataLoaded: false,
                areas: [],

                columns: [
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Territory',
                        displayType: 0,
                        field: 'territory',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'District',
                        displayType: 0,
                        field: 'district',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Color',
                        displayType: 2,
                        field: 'color',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ]

            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/area')
                loadPromise.then(function(response) {
                    thisIns.areas = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            async removeArea(id) {
                try {

                    const confirmDelete = await this.$confirmDialog('Do you want to remove this area?')
                    
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/management/v1/area/${  id}`)
                    this.$printSuccess('Area removed')
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)                    
                } finally {
                    this.loadData()
                }
            }
        },
        computed: {
            formattedData() {
                return this.areas.map((area, index) => {
                    return {
                        index,
                        id: area.id,
                        name: area.name,
                        color: area.color,
                        territory: (area.territory_id !== this.nullObjectId) ? area.territory : 'Not defined',
                        district: (area.district_id !== this.nullObjectId) ? area.district : 'Not defined'
                    }
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>